































































































































@import '~@/assets/styles/helpers/_variables.scss';
.menu-list {
  background-color: #FFF;

  .title-list {
    cursor: pointer;
  }
}

.selected_types_lancamentos {
  color: $colorPrimaryDarken;
}
